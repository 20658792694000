import { Cart, CartItem, Character, PrintAtHome } from '@/services/models';
import { useMemo } from 'react';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';

let store: any;

const reducers = combineReducers({
  productCart: (state: Cart | null = null, action) => {
    switch (action.type) {
      case 'LOAD_CART': {
        return {
          ...state,
          ...action.payload
        };
      }
      case 'ADD_CART_ITEM': {
        return {
          ...state,
          ...action.payload,
          cartItems: action.payload.cartItems.map((item: CartItem) => item)
        };
      }
      case 'UPDATE_CART_ITEM_QUANTITY': {
        return action.payload;
      }
      case 'REMOVE_PROD_CART': {
        return action.payload;
      }
      case 'UPDATE_QTY': {
        return action.payload;
      }
      default:
        return state;
    }
  },
  booksBuildKeys: (state = null, action) => {
    switch (action.type) {
      case 'POPULATE_BOOKS_BUILD_KEYS': {
        return action.payload;
      }
      default:
        return state;
    }
  },
  configSetupBuildKey: (state = null, action) => {
    switch (action.type) {
      case 'LOAD_CONFIG_SETUP_BUILD_KEY': {
        return action.payload;
      }
      default:
        return state;
    }
  },
  productList: (state = null, action) => {
    switch (action.type) {
      case 'LOAD_PRODUCT_LIST': {
        return action.payload;
      }
      default:
        return state;
    }
  },
  characterPreviewBookKey: (state = null, action) => {
    switch (action.type) {
      case 'LOAD_CHARACTER_PREVIEW_BOOK_KEY': {
        return action.payload;
      }
      default:
        return state;
    }
  },
  characterPreview: (
    state: Character | null = null,
    action
  ): Character | null => {
    switch (action.type) {
      case 'UPDATE_CHARACTER': {
        return {
          ...state,
          ...action.payload,
          currentSelection: { ...action.payload.currentSelection }
        };
      }
      case 'SET_CURRENT_CHARACTER_NAME': {
        if (state) {
          return {
            ...state,
            name: action.payload
          };
        }
        return state;
      }
      case 'SET_CURRENT_CHARACTER_LAST_NAME': {
        if (state) {
          return {
            ...state,
            lastName: action.payload
          };
        }
        return state;
      }
      case 'SET_CURRENT_CHARACTER_GENDER': {
        if (state) {
          return {
            ...state,
            gender: action.payload
          };
        }
        return state;
      }
      default:
        return state;
    }
  },
  characterConfigured: (state = false, action) => {
    switch (action.type) {
      case 'SET_CHARACTER_CONFIGURED': {
        return action.payload;
      }
      default:
        return state;
    }
  },
  printAtHome: (
    state: PrintAtHome = { name: null, email: null, assetCode: null },
    action
  ) => {
    switch (action.type) {
      case 'SET_PAH_NAME': {
        if (state) {
          return {
            ...state,
            name: action.payload
          };
        }
        return state;
      }
      case 'SET_PAH_EMAIL': {
        if (state) {
          return {
            ...state,
            email: action.payload
          };
        }
        return state;
      }
      case 'SET_PAH_ASSET_CODE': {
        if (state) {
          return {
            ...state,
            assetCode: action.payload
          };
        }
        return state;
      }
      default:
        return state;
    }
  },
  pahKeys: (state = null, action) => {
    switch (action.type) {
      case 'POPULATE_PAH_KEYS': {
        return action.payload;
      }
      default:
        return state;
    }
  },
  currentStep: (state = 1, action) => {
    switch (action.type) {
      case 'SET_CURRENT_STEP': {
        return action.payload;
      }
      default:
        return state;
    }
  },
  currentStepPah: (state = 1, action) => {
    switch (action.type) {
      case 'SET_CURRENT_STEP_PAH': {
        return action.payload;
      }
      default:
        return state;
    }
  }
});

export const initStore = (initialState: any) => {
  const reduxDebug =
    typeof window !== 'undefined'
      ? (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
        (window as any).__REDUX_DEVTOOLS_EXTENSION__()
      : undefined;

  const store = createStore(
    reducers,
    initialState,
    composeWithDevTools(applyMiddleware(thunkMiddleware))
    // reduxDebug
  );

  return store;
};

export const initializeStore = (preloadedState: any): any => {
  let _store = store ?? initStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState
    });

    // Reset the current store
    store = undefined;
  }

  if (typeof window === 'undefined') return _store;
  // Create the store once in the client
  if (!store) store = _store;

  return _store;
};

export function useStore(initialState: any) {
  const store = useMemo(() => initializeStore(initialState), [initialState]);
  return store;
}
