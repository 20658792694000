import mixpanel from 'mixpanel-browser';

const token = process.env.NEXT_PUBLIC_CF_REACT_APP_MIXPANEL_TOKEN || '';

mixpanel.init(token, { debug: true });

const track = (message: string, payload?: any): void => {
  mixpanel.track(message, payload);
};

export { track };
