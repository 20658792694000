import React from 'react';
import type { AppProps } from 'next/app';
import '../styles/globals.css';
import { Provider } from 'react-redux';
import { useStore } from '../store/storeConfig';
import dynamic from 'next/dynamic';

import '../styles/icon-styles.global.css';
import ErrorBoundary from '@/components/ErrorBoundary';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Script from 'next/script';

const DynamicComponent = dynamic(
  (): any => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const ReactPixel = require('react-facebook-pixel').default;
      ReactPixel.init('1468884529927007');
      ReactPixel.pageView();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.ReactPixel = ReactPixel;
    });
  },
  { ssr: false }
);

export function reportWebVitals(metric: any): void {
  console.log(metric);
}

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
  const store = useStore(pageProps.initialReduxState);
  return (
    <ErrorBoundary>
      <Script
        strategy="afterInteractive"
        src="https://www.googletagmanager.com/gtag/js?id=G-DVQ90B9JTK"
      />
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-DVQ90B9JTK', {
              page_path: window.location.pathname,
            });
            window.gtag = gtag;
          `
        }}
      />

      {/* <!-- Hotjar Tracking Code for https://www.keepsaketales.com/ --> */}
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:2739395,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');        
          `
        }}
      />

      <Provider store={store}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <DynamicComponent />
        <Component {...pageProps} />
      </Provider>
    </ErrorBoundary>
  );
}

export default MyApp;
